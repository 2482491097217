<template>
    <div class="wrapper">
        <div class="wrapper__img mb-2">
            <img src="@/assets/icons/not-found.svg" alt="not-found" />
        </div>
        <Button
            class="w-16"
            theme="red"
            @click="$router.push({ name: 'home' })"
        >
            Go Home
        </Button>
    </div>
</template>

<script>
export default {
    name: "NotFound",
};
</script>

<style scoped lang="scss">
@use "../../assets/scss/mixins/index" as *;

.wrapper {
    width: 100%;
    height: 100vh;
    display: grid;
    place-items: center;

    &__img {
        width: 650px;

        @include query("mobile") {
            width: 330px;
        }

        & img {
            width: 100%;
        }
    }
}
</style>
